export const de = {
    "BACK_BUTTON": "Zurück",
    "NEXT_BUTTON": "Weiter",
    "FINISH_BUTTON": "Fertig",
    "CHOICES_TITLE": "Auswahl",
    "SELECT_INPUT": "Auswählen",
    "EMPTY_ERROR": "Pflichtfeld",
    "SENDING_DATA": "Daten werden gesendet...",
    "LOADING": "Laden...",
    "MAX_SELECT_ERROR": "Maximal ausgewählt ist:",
    "FORM_ALREADY_ANSWERED": "Danke für Ihre Antwort!",
    "THANK_YOU": "Danke!",
    "MAX_SELECT_CUSTOM_MESSAGE": "Sie haben den maximalen Optionswert erreicht",
    "SKILL_PLACEHOLDER": "Fähigkeitsstufe",
    "companyValues": {
        "PASSION": "Leidenschaft für alles, was wir tun",
        "INNOVATING": "Immer innovativ",
        "CONFIDENCE": "Mit Vertrauen und Verantwortung",
        "WINNING": "Gewinnen als Team",
        "DIFFERENCE": "Engagiert, einen Unterschied zu machen"
    }
}