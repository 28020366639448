export const ja = {
  "BACK_BUTTON": "戻る",
  "NEXT_BUTTON": "次へ",
  "I_AGREE": "賛成です",
  "FINISH_BUTTON": "完了",
  "CHOICES_TITLE": "選択肢",
  "SELECT_INPUT": "名前",
  "EMPTY_ERROR": "必須項目です",
  "SENDING_DATA": "データを送信中...",
  "LOADING": "読み込み中...",
  "MAX_SELECT_ERROR": "選択可能な最大数は：",
  "FORM_ALREADY_ANSWERED": "ご回答ありがとうございます！",
  "THANK_YOU": "Organizational Network Analysis (ONA) 調査にご協力いただき、誠にありがとうございます。フィードバックが送信されました。",
  "MAX_SELECT_CUSTOM_MESSAGE": "最大オプション値に達しました",
  "SKILL_PLACEHOLDER": "スキルレベル",
  "companyValues": {
    "PASSION": "私たちが行うすべてに情熱を持っています",
    "INNOVATING": "常に革新しています",
    "CONFIDENCE": "自信と責任を持って",
    "WINNING": "チームとして勝利します",
    "DIFFERENCE": "違いを生み出すことにコミットしています"
  },
}