import React, { useState, useRef, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import Select from "components/select";
import { SkillQuestionSelect } from "components/skillQuestion";
import { CompanyValuesSelect } from "components/companyValuesQuestion";
import Button from "components/button";
import Card from "components/card";
import Stepper from "components/stepper";
import LikertScaleQuestion from "components/likertScaleQuestion";

import { t } from "intl/index";
import debounce from 'lodash/debounce';
import axios from "axios";

import styles from "components/wizard/styles";
import useAppStore from "stores/appStore";

// TODO: move to data dir along with files from question form

const selectTypes = {
  MULTI: "multiSelect",
  SINGLE: "singleSelect",
  SINGLE_WITH_OPTION: "singleSelectWithOption",
  COMPANY_VALUES: "companyValues",
  LIKERT_SCALE: "likertScale",
};

const Wizard = ({
  options: propsOptions,
  questions,
  activeStep,
  onNextClick,
  onBackClick,
  settings,
  prevAnswers = [],
}) => {
  const [answers, setAnswers] = useState([]);
  const [prevAnswersLoaded, setPrevAnswersLoaded] = useState(false);
  const [likertChoice, setLikertChoice] = useState("");
  const [error, setError] = useState("");
  const [skillError, setSkillError] = useState("");
  const isLastStep = questions.length - 1 === activeStep;
  const isFirstStep = activeStep === 0;
  const { lang, accessToken } = useAppStore();
  const singleWithOptionRef = useRef();
  const companyValuesRef = useRef();
  const [search, setSearch] = useState('');
  const [options, setOptions] = useState(propsOptions ?? []);

  const url = (new URL(window.location))
  const surveyId = url.searchParams.get("surveyId");
  const hash = url.searchParams.get("hash");

  const fetchOptions = useCallback(
    debounce((searchTerm) => {
      try {
        axios
          .get(
            `${process.env.REACT_APP_FASTIFY_API_URL}survey-participants/name-email-search`,
            {
              params: { search: searchTerm, surveyId: surveyId },
              headers: { Authorization: `Bearer ${accessToken}` },
            }
          )
          .then((response) => {
            setOptions(
              response.data
                .filter(participant => participant.id !== hash)
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((participant) => ({
                  label: `${participant.name} (${participant.email.split('@')[0]})`,
                  value: participant.id,
                }))
            );
          });
      } catch (error) {
        console.error(error);
      }
    }, 300),
    []
  );

  useEffect(() => {
    if (search) {
      console.log('searching for:', search);
      fetchOptions(search);
    } else {
      setOptions([]);
    }
  }, [search]);

  const handleInputChange = (inputValue) => {
    setSearch(inputValue);
  };

  useEffect(() => {
    // if prevAnswers is not empty, set the answers to the prevAnswers
    if (prevAnswers.length > 0 && answers.length === 0 && !prevAnswersLoaded) {
      setAnswers(prevAnswers);
      setPrevAnswersLoaded(true);
    }
  }, [answers, prevAnswers]);

  const handleLikertChange = (e) => {
    setLikertChoice(e.target.value);
  };

  const handleSubmit = () => {
    const questionType = questions[activeStep].type;

    if (questionType === selectTypes.SINGLE_WITH_OPTION) {
      const values = singleWithOptionRef?.current?.getState();
      const allSkills = values.map((value) => value.skill);
      if (allSkills.includes("")) {
        setSkillError(t(lang).EMPTY_ERROR);
        return;
      }
      onNextClick(values);
      setAnswers([]);
      setError("");
      setSkillError("");
      return;
    }

    if (questionType === selectTypes.COMPANY_VALUES) {
      const values = companyValuesRef?.current?.getState();
      onNextClick(values);
      setAnswers([]);
      setError("");
      return;
    }

    if (questionType === selectTypes.LIKERT_SCALE) {
      // Uncomment this line and comment or delete the line below it to have a more detailed answer overview
      // onNextClick({ question: questions[activeStep].title, likertChoice});
      if (!likertChoice) {
        setError(t(lang).EMPTY_ERROR);
      } else {
        onNextClick(likertChoice);
        setLikertChoice("");
        setAnswers([]);
        setError("");
      }

      return;
    }

    if (answers.length === 0) {
      setError(t(lang).EMPTY_ERROR);
      return;
    }
    setPrevAnswersLoaded(false);
    onNextClick(answers);
    setAnswers([]);
    setError("");
  };

  const handleBack = () => {
    onBackClick();
    setAnswers([]);
    setPrevAnswersLoaded(false);
    setError("");
  };

  const handleChange = (choices) => {
    if (choices && choices.length === 20) {
      setError(t(lang).MAX_SELECT_ERROR + " 20");
      return;
    }
    setAnswers(choices || []);
    setError("");
  };

  return (
    <Card>
      <styles.QuestionWrapper>
        <h3>{questions[activeStep].title}</h3>
        {activeStep + 1}. &nbsp; {questions[activeStep].name}
      </styles.QuestionWrapper>
      {
        {
          [selectTypes.MULTI]: (
            <Select
              options={options[0] && options[0].hasOwnProperty('label') ? options : options.map((option) => ({
                label: option.name,
                value: option.id,
              }))}
              onChange={handleChange}
              onInputChange={handleInputChange}
              value={answers}
              isMulti
              placeholder={t(lang).SELECT_INPUT}
            />
          ),
          [selectTypes.LIKERT_SCALE]: (
            <LikertScaleQuestion
              options={options}
              likertChoice={likertChoice}
              likertOptions={[
                "Strongly disagree",
                "Disagree",
                "Neutral",
                "Agree",
                "Strongy agree",
              ]}
              onChange={handleLikertChange}
            />
          ),
          [selectTypes.SINGLE]: (
            <Select
              options={options.map((option) => ({
                label: option.name,
                value: option.id,
              }))}
              onChange={handleChange}
              value={answers}
              placeholder={t(lang).SELECT_INPUT}
              isMulti={false}
            />
          ),
          [selectTypes.SINGLE_WITH_OPTION]: (
            <SkillQuestionSelect
              options={options.map((option) => ({
                label: option.name,
                value: option.id,
              }))}
              placeholder={t(lang).SELECT_INPUT}
              componentRef={singleWithOptionRef}
              langMap={t(lang)}
              error={skillError}
              onSkillChange={() => setSkillError("")}
            />
          ),
          [selectTypes.COMPANY_VALUES]: (
            <CompanyValuesSelect
              options={options.map((option) => ({
                label: option.name,
                value: option.id,
              }))}
              placeholder={t(lang).SELECT_INPUT}
              componentRef={companyValuesRef}
              langMap={t(lang)}
            />
          ),
        }[questions[activeStep].type]
      }

      <styles.Error>{error}</styles.Error>
      <Stepper activeStep={activeStep} steps={questions.length} />
      <styles.Back>
        <Button
          isDisabled={isFirstStep}
          onClick={handleBack}
          title={t(lang).BACK_BUTTON}
          type={!isLastStep ? "secondary" : "primary"}
        />
      </styles.Back>

      <styles.Next>
        <Button
          type={isLastStep ? "secondary" : "primary"}
          onClick={handleSubmit}
          title={isLastStep ? t(lang).FINISH_BUTTON : t(lang).NEXT_BUTTON}
        />
      </styles.Next>
    </Card>
  );
};

Wizard.propTypes = {
  options: PropTypes.array.isRequired,
  questions: PropTypes.array.isRequired,
  activeStep: PropTypes.number.isRequired,
  onNextClick: PropTypes.func.isRequired,
  onBackClick: PropTypes.func.isRequired,
  settings: PropTypes.object.isRequired,
};

export default Wizard;
