export const it = {
  "BACK_BUTTON": "Ritorno",
  "NEXT_BUTTON": "Il prossimo",
  "FINISH_BUTTON": "Finire",
  "CHOICES_TITLE": "Scelte",
  "SELECT_INPUT": "Selezionare",
  "EMPTY_ERROR": "Non può essere vuoto!",
  "SENDING_DATA": "Dati inviati!",
  "LOADING": "Caricamento...",
  "MAX_SELECT_ERROR": "Il limite massimo è",
  "FORM_ALREADY_ANSWERED": "Hai già compilato il form! Grazie",
  "MAX_SELECT_CUSTOM_MESSAGE": "Hai raggiunto il valore massimo dell'opzione",
  "SKILL_PLACEHOLDER": "Livello di abilità",
  "companyValues": {
    "PASSION": "Passione per tutto ciò che facciamo",
    "INNOVATING": "Innovare sempre",
    "CONFIDENCE": "Con fiducia e responsabilità",
    "WINNING": "Vincere in squadra",
    "DIFFERENCE": "Impegnati a fare la differenza"
  },
  "THANK_YOU": "Grazie!"
}
