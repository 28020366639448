export const en = {
  "BACK_BUTTON": "Back",
  "NEXT_BUTTON": "Next",
  "I_AGREE": "I agree",
  "FINISH_BUTTON": "Finish",
  "CHOICES_TITLE": "Choices",
  "SELECT_INPUT": "Input Name",
  "EMPTY_ERROR": "Required field",
  "SENDING_DATA": "Sending data...",
  "LOADING": "Loading...",
  "MAX_SELECT_ERROR": "Max selected is:",
  "FORM_ALREADY_ANSWERED": "Thank you for answering!",
  "THANK_YOU": "Thank you for your participation in the Organizational Network Analysis (ONA) survey. Your feedback has been submitted.",
  "MAX_SELECT_CUSTOM_MESSAGE": "You have reached the max option's value",
  "SKILL_PLACEHOLDER": "Skill level",
  "companyValues": {
    "PASSION": "Passion for everything we do",
    "INNOVATING": "Always innovating",
    "CONFIDENCE": "With confidence and responsibility",
    "WINNING": "Winning as a team",
    "DIFFERENCE": "Committed to make a difference"
  }
}