import React from "react";
import Paper from '@material-ui/core/Paper';
import styled from "styled-components";

const CardWrapper = styled(Paper)`
  width: 80%;
  max-width: 800px;
  height: max-content;
  padding: 25px;
  padding-bottom: 40px;
  position: relative;
  margin-top: 48px;
  margin-left: auto;
  margin-right: auto;
  overflow: visible;

  @media (max-width: 768px) {
    width: 90%;
    padding: 20px;
    overflow: visible;
  }
`;

const Card = ({ children }) => {
  return <CardWrapper>{children}</CardWrapper>;
};

export default Card;
