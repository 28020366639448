import styled from "styled-components";
const Header = styled.div`
  position: relative;
  z-index: 2;
  text-align: center;
  line-height: 4.5rem;
  font-size: 20px;
  font-weight: bold;
  background: #fff;
  width: 100%;
  height: 4rem;
  border-top: 0.4rem solid;
  border-image: ${(props) =>
    `linear-gradient(to right, ${props.secondaryColor} 0%, ${props.primaryColor} 100%)`};
  border-image-slice: 2;
  padding: 1rem 0rem 0.5rem 0rem;
`;

const Body = styled.div`
  position: relative;
  z-index: 1;
  flex: 1;
  display: flex;
  flex-direction: column; // Ensure children stack vertically
  align-items: center; // Center horizontally
  overflow: visible; // Enable vertical scrolling if content overflows
`;
const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: max-content;
    width: 100%;
    overflow: visible;
  `

const Logo = styled.img`
  vertical-align: start;
  max-height: 48px;
`;

const LogoContainer = styled.div`
  width: 100%;
  justify-content: center;
`;



export default {
  Container,
  Header,
  Body,
  Logo,
  LogoContainer,
};
