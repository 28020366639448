import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ReactHtmlParser from "react-html-parser";
import styled from "styled-components";
import Layout from "layout";
import Button from "components/button";
import Card from "components/card";
import { t } from "intl/index";
import useAppStore from "stores/appStore";

const Center = styled.div`
  text-align: center;
`;

const Wrapper = styled.div`
  line-height: 1.6;
  text-align: justify;
  text-justify: inter-word;

  @media (max-width: 768px) {
    text-align: left;
  }
`;

// Add a new styled component for the mobile fixed container
const FixedContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  padding: 5.5px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  
  @media (min-width: 769px) {
    position: static;
    transform: none;
    box-shadow: none;
    background: transparent;
    padding: 0;
  }
`;

const CheckboxContainer = styled.div`
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    margin: 0;
    margin-right: 10px;
  }
`;

const Welcome = ({
  onNextClick,
  welcomePageString,
  welcomePageString2,
  settings,
}) => {
  const [openSecondPage, setSecondPage] = useState(false);
  const [isAgreed, setIsAgreed] = useState(false);
  const { lang, getIsMobile } = useAppStore();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [openSecondPage]);

  const handleCheckboxChange = (e) => {
    setIsAgreed(e.target.checked);
  };

  const handleClick = () => {
    if (openSecondPage) {
      onNextClick();
    } else {
      setSecondPage(true);
    }
  };

  console.log("next disabled: ", openSecondPage && !isAgreed);
  return (
    <Layout settings={settings}>
      <Card>
        {openSecondPage ? (
          <Wrapper>
            {ReactHtmlParser(welcomePageString2)}
            {getIsMobile() ? (
              <FixedContainer>
                <CheckboxContainer>
                  <input
                    type="checkbox"
                    id="agree"
                    checked={isAgreed}
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor="agree" style={{ marginLeft: '8px' }}>
                    {t(lang).I_AGREE}
                  </label>
                </CheckboxContainer>
              </FixedContainer>
            ) : (
              <CheckboxContainer>
                <input
                  type="checkbox"
                  id="agree"
                  checked={isAgreed}
                  onChange={handleCheckboxChange}
                />
                <label htmlFor="agree" style={{ marginLeft: '8px' }}>
                  {t(lang).I_AGREE}
                </label>
              </CheckboxContainer>
            )}
          </Wrapper>
        ) : (
          <Wrapper>{ReactHtmlParser(welcomePageString)}</Wrapper>
        )}
        <Center>
          <Button
            onClick={handleClick}
            title={t(lang).NEXT_BUTTON}
            isFixed={getIsMobile()}
            isDisabled={openSecondPage && !isAgreed}
          />
        </Center>
      </Card>
    </Layout>
  );
};

Welcome.propTypes = {
  onNextClick: PropTypes.func.isRequired,
  settings: PropTypes.object,
  welcomePageString: PropTypes.string,
  welcomePageString2: PropTypes.string,
};

export default Welcome;
