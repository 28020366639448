import React, { useState, useEffect } from "react";
import styles from "layout/styles";
import { MenuItem, Select, FormControl } from '@material-ui/core'
import { styled } from '@material-ui/core/styles';
import { FlagIcon, FlagIconCode } from 'react-flag-kit';
import { storeLangSettings } from "utils/language";
import useAppStore from "stores/appStore";

const LanguageSelect = styled(FormControl)`
`;

const Layout = ({ settings, withoutHeader = false, children }) => {
  const { lang, setLang, setIsMobile, getIsMobile } = useAppStore();
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleChange = (event) => {
    storeLangSettings(event.target.value);
    setLang(event.target.value);
  };


  return (
    <styles.Container>
      {!withoutHeader && (
        <styles.Header
          primaryColor={settings?.primaryColor}
          secondaryColor={settings?.secondaryColor}
        >
          <styles.LogoContainer>
            <styles.Logo src={settings?.logoBase64} alt="logo" />
          </styles.LogoContainer>
          <LanguageSelect
            variant="standard"
            style={{
              position: getIsMobile() ? 'fixed' : 'absolute',
              left: getIsMobile() ? '1rem' : '',
              right: getIsMobile() ? '' : '1rem',
              top: getIsMobile() ? '' : '1rem',
              bottom: getIsMobile() ? '1rem' : '',
              width: '150px',
            }}
          >
            <Select
              labelId="language-select-label"
              id="language-select"
              value={lang}
              onChange={handleChange}
              label="Language"
              style={{
                "background": "rgba(255, 255, 255, 0.1)",
                "backdropFilter": "blur(2px)",
                "boxShadow": "inset 0 0 10px rgba(255, 255, 255, 0.3)",
                "borderRadius": "4px",
                "overflow": "hidden",
                "border": "none",
              }}
            >
              <MenuItem value="en">
                <FlagIcon code="US" width={24} height={20} style={{ marginRight: '8px' }} /> English
              </MenuItem>
              <MenuItem value="es">
                <FlagIcon code="ES" width={24} height={20} style={{ marginRight: '8px' }} /> Español
              </MenuItem>
              <MenuItem value="ja">
                <FlagIcon code="JP" width={24} height={20} style={{ marginRight: '8px' }} /> 日本語
              </MenuItem>
              {/* <MenuItem value="it">
                <FlagIcon code="IT" width={24} height={20} style={{ marginRight: '8px' }} /> Italiano
              </MenuItem>
              <MenuItem value="de">
                <FlagIcon code="DE" width={24} height={20} style={{ marginRight: '8px' }} /> Deutsch
              </MenuItem>
              <MenuItem value="pt">
                <FlagIcon code="BR" width={24} height={20} style={{ marginRight: '8px' }} /> Português
              </MenuItem>
              <MenuItem value="fr">
                <FlagIcon code="FR" width={24} height={20} style={{ marginRight: '8px' }} /> Français
              </MenuItem>
              <MenuItem value="zh">
                <FlagIcon code="CN" width={24} height={20} style={{ marginRight: '8px' }} /> 中文
              </MenuItem> */}
            </Select>
          </LanguageSelect>
        </styles.Header>
      )}

      <styles.Body>{children}</styles.Body>
    </styles.Container >
  );
};

export default Layout;
