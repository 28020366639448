import { en } from "intl/en";
import { es } from "intl/es";
import { pt } from "intl/pt";
import { it } from "intl/it";
import { ja } from "intl/ja";
import { de } from "intl/de";

export const t = (lang) => {
  if (lang === "en") return en;
  if (lang === "es") return es;
  if (lang === "pt") return pt;
  if (lang === "it") return it;
  if (lang === "ja") return ja;
  if (lang === "de") return de;
  return en;
};
