import { create } from 'zustand';
import axios from 'axios';

// const fetchTranslatedText = async (lang, text, accessToken) => {
//     try {
//         const response = await axios.post(
//             `${process.env.REACT_APP_FASTIFY_API_URL}translate`,
//             { text, targetLang: lang },
//             {
//                 headers: {
//                     Authorization: `Bearer ${accessToken}`
//                 }
//             }
//         );
//         return response.data.translatedText;
//     } catch (error) {
//         console.error('Error fetching translated text:', error);
//         throw error;
//     }
// };

const fetchBatchTranslatedTexts = async (lang, texts, accessToken) => {
    try {
        const translationRequests = texts.map((text) => ({ text, targetLang: lang }));
        const response = await axios.post(
            `${process.env.REACT_APP_FASTIFY_API_URL}translate/batch`,
            { translations: translationRequests },
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            }
        );
        return response.data.translations; // Array of translated objects
    } catch (error) {
        console.error('Batch translation error:', error);
        throw error;
    }
};


const useAppStore = create((set, get) => ({
    lang: "",
    setLang: async (newLang) => {
        set({ lang: newLang, tLoading: true });
        const { originalQuestions } = get();
        await get().setTranslatedQuestions(originalQuestions);
    },
    accessToken: '',
    setAccessToken: (newAccessToken) => set({ accessToken: newAccessToken }),
    tLoading: true,
    setTLoading: (newTLoading) => set({ tLoading: newTLoading }),
    questions: [],
    originalQuestions: [],
    setOriginalQuestions: (newQuestions) => set({ originalQuestions: newQuestions }),
    setTranslatedQuestions: async (newQuestions, inputLang = "") => {
        const { lang, accessToken } = get();
        set({ tLoading: true });
        const targetLang = inputLang || lang;

        // Prepare texts to translate
        const textsToTranslate = [];
        newQuestions.forEach((question) => {
            textsToTranslate.push({ id: question.id, field: 'title', text: question.title });
            textsToTranslate.push({ id: question.id, field: 'name', text: question.name });
        });

        // Fetch translations in batch
        const translatedTexts = await fetchBatchTranslatedTexts(
            targetLang,
            textsToTranslate.map(item => item.text),
            accessToken
        );

        // Map translations back to questions
        const translatedQuestions = newQuestions.map((question) => {
            const translatedTitle = translatedTexts.find(
                (t) => t.text === question.title && t.targetLang === targetLang
            )?.translatedText || question.title;

            const translatedName = translatedTexts.find(
                (t) => t.text === question.name && t.targetLang === targetLang
            )?.translatedText || question.name;

            return {
                ...question,
                title: translatedTitle,
                name: translatedName,
            };
        });

        set({ questions: translatedQuestions, tLoading: false });
    },
    isMobile: window.innerWidth <= 768,
    setIsMobile: (newIsMobile) => set({ isMobile: newIsMobile }),
    getIsMobile: () => get().isMobile,
}));

export default useAppStore;