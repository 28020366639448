export const pt = {
  "BACK_BUTTON": "Voltar",
  "NEXT_BUTTON": "Próximo",
  "FINISH_BUTTON": "Concluir",
  "CHOICES_TITLE": "Escolhas",
  "SELECT_INPUT": "Selecione",
  "EMPTY_ERROR": "Campo obrigatório!",
  "SENDING_DATA": "dados enviados!",
  "LOADING": "Carregando...",
  "MAX_SELECT_ERROR": "O limite máximo é ",
  "FORM_ALREADY_ANSWERED": "A pesquisa foi respondida!",
  "THANK_YOU": "Obrigado!",
  "MAX_SELECT_CUSTOM_MESSAGE": "Você atingiu o valor da opção máxima",
  "SKILL_PLACEHOLDER": "Nível de habilidade",
  "companyValues": {
    "PASSION": "Paixão por tudo o que fazemos",
    "INNOVATING": "Sempre inovando",
    "CONFIDENCE": "Com confiança e responsabilidade",
    "WINNING": "Vencendo como uma equipe",
    "DIFFERENCE": "Comprometido em fazer a diferença"
  }
}

