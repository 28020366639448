import React from "react";
import styled from "styled-components";

import Layout from "layout";
import Card from "components/card";
import { getLangSettings } from "utils/language";
import { t } from "intl/index";
import useAppStore from "stores/appStore";

const Wrapper = styled.div`
  text-align: center;
`;

const ThankYouForAnswering = () => {
  const { lang } = useAppStore();
  const LANG_MAP = t(lang);

  return (
    <Layout withoutHeader={true}>
      <Card>
        <Wrapper>
          <h3>{LANG_MAP.THANK_YOU}</h3>
        </Wrapper>
      </Card>
    </Layout>
  )
}

export default ThankYouForAnswering;
