export const es = {
  "BACK_BUTTON": "Volver",
  "NEXT_BUTTON": "Siguiente",
  "I_AGREE": "De acuerdo",
  "FINISH_BUTTON": "Terminar",
  "CHOICES_TITLE": "Respuesta",
  "SELECT_INPUT": "Nombre",
  "EMPTY_ERROR": "Selecciona al menos una opción de respuesta",
  "SENDING_DATA": "datos enviados!",
  "LOADING": "Cargando...",
  "MAX_SELECT_ERROR": "Límite máximo es",
  "FORM_ALREADY_ANSWERED": "Gracias por tu participación. Tus resultados se han enviado correctamente.",
  "MAX_SELECT_CUSTOM_MESSAGE": "Ha alcanzado el valor máximo de la opción",
  "SKILL_PLACEHOLDER": "Herramientas/conocimientos",
  "companyValues": {
    "PASSION": "Pasión por todo lo que hacemos",
    "INNOVATING": " Siempre innovando",
    "CONFIDENCE": "Con confianza y responsabilidad",
    "WINNING": "Ganando en equipo",
    "DIFFERENCE": "Comprometidos para marcar la diferencia"
  },
  "THANK_YOU": "Gracias por su participación en la encuesta de Análisis de Redes Organizacionales (ONA). Sus comentarios han sido enviados."
}